import { Capacitor } from '@capacitor/core';
import {
  Glassfy,
  GlassfyOffering,
  GlassfyPurchaseHistory,
  GlassfySku,
  GlassfyTransaction
} from 'capacitor-plugin-glassfy';
import { Injectable, inject } from '@angular/core';
import { PurchasesService } from '../purchases/purchases.service';
import { RemoteConfig } from '@app/enums/remote-config.enum';
import { RemoteConfigService } from '@app/services/remote-config/remote-config.service';
import { ToastService } from '../toast/toast.service';
import { UsersService } from '../users/users.service';

@Injectable({
  providedIn: 'root'
})
export class IapService {
  private readonly purchaseService: PurchasesService = inject(PurchasesService);
  private readonly remoteConfig: RemoteConfigService = inject(RemoteConfigService);
  private readonly toastService: ToastService = inject(ToastService);
  private readonly usersService: UsersService = inject(UsersService);

  /*
   * Initialize Glassfy SDK
   */
  public async initialize(): Promise<void> {
    if (Capacitor.isNativePlatform()) {
      try {
        await Glassfy.initialize({
          apiKey: await this.remoteConfig.getString(RemoteConfig.GLASSFY_API_KEY),
          watcherMode: true
        });
      } catch (e) {
        console.log('🚀 ~ IapService ~ initialize ~ e:', e);
        // initialization error
      }
    }
  }

  /*
   * Get offerings and sort them by order from extravar object
   */
  public async getOfferings(): Promise<GlassfyOffering[]> {
    if (Capacitor.isNativePlatform()) {
      const offerings = await Glassfy.offerings();
      return offerings.all.sort((a, b) => {
        // Check if skus array exists and has at least one item
        if (!Array.isArray(a.skus) || a.skus.length === 0 || !Array.isArray(b.skus) || b.skus.length === 0) {
          return 0; // or throw an error if you prefer
        }

        // Check if extravars object exists and has an order property
        if (
          !a.skus[0].extravars ||
          !b.skus[0].extravars ||
          !('order' in a.skus[0].extravars) ||
          !('order' in b.skus[0].extravars)
        ) {
          return 0; // or throw an error if you prefer
        }

        const orderA = parseInt(a.skus[0].extravars['order'], 10);
        const orderB = parseInt(b.skus[0].extravars['order'], 10);

        // Check if orderA and orderB are valid numbers
        if (isNaN(orderA) || isNaN(orderB)) {
          return 0; // or throw an error if you prefer
        }

        return orderA - orderB;
      });
    } else {
      return [];
    }
  }

  /*
   * Purchase a SKU and update user's credits
   */
  public async purchase(sku: GlassfySku): Promise<void> {
    try {
      const transaction = await Glassfy.purchaseSku({ sku });
      await this.handleTransaction(transaction, sku);
    } catch (error: unknown) {
      let text;
      if (error instanceof Error) {
        switch (error['message']) {
          case 'The operation couldn’t be completed. (SKErrorDomain error 2.)':
            break;
          case 'Transaction receipt is not validated':
            text = 'toast_iap_error_not_validated';
            break;
          case 'SKU credits is not a valid number':
            text = 'toast_iap_error_sku_credits';
            break;
          default:
            text = null;
        }
        if (text) {
          // purchase error
          await this.toastService.showToast({
            text
          });
        }
      }
      throw error;
    }
  }

  private async handleTransaction(transaction: GlassfyTransaction, sku: GlassfySku): Promise<void> {
    if (Capacitor.getPlatform() === 'ios' && !transaction.receiptValidated) {
      throw new Error('Transaction receipt is not validated');
    }

    if (sku.extravars && sku.extravars['credits']) {
      const credits = this.usersService.entity.value?.credits || 0;
      const skuCredits = parseInt(sku.extravars['credits'], 10);
      if (isNaN(skuCredits)) {
        throw new Error('SKU credits is not a valid number');
      }
      await this.usersService.updateProfile({
        credits: credits + skuCredits
      });
      await this.purchaseService.addPurchase({
        created: new Date().toISOString(),
        sku,
        transaction
      });
      return;
    } else {
      throw new Error('No SKU credits found. Product misconfiguration.');
    }
  }

  /*
   * Used to retrieve user's purchases history
   */
  public async getPurchasesHistory(): Promise<GlassfyPurchaseHistory[]> {
    const purchases = await Glassfy.purchaseHistory();
    return purchases.all.length ? purchases.all : [];
  }
}
